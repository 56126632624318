import './App.css';
// import './Home.scss';
import React,{useState} from 'react';
import Header_Image from "./site_images/Header_image.png"
import Header_Logo from "./site_images/Header_logo.png"
import Header_text_left from "./site_images/Header_text_left.png"
import Customer_service_line from "./site_images/Customer_service.png"
import graphic2 from "./site_images/graphic2.png"
import service1 from "./site_images/01.png"
import service2 from "./site_images/02.png"
import service3 from "./site_images/03.png"
import service4 from "./site_images/04.png"
import graphic1 from "./site_images/graphic1.png"
import { BsArrowRight } from 'react-icons/bs'
import {Helmet} from "react-helmet";
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

function App() {
  const [error_name, seterror_name] = useState("")
  const [error_email, seterror_email] = useState("")
  const [error_recapcha, seterror_recapcha] = useState("")
  const [message, setmessage] = useState("")
  const [capchavalue, setcapchavalue] = useState("");
  const [checkclicksubmit, setcheckclicksubmit] = useState(false);
  const currentYear = new Date().getFullYear();


  function onChange(value) {
    setcapchavalue(value)
  }

  function onExpired(){
    setcapchavalue("")
  }
  function isValidEmail(email) {
    // Regular expression pattern for valid email addresses
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  function handleSubmitForm(event){
    setcheckclicksubmit(true)
    event.preventDefault();
    let name = document.getElementById("contact_name").value
    let email = document.getElementById("contact_email").value
    let subject = document.getElementById("contact_subject").value
    let introduction = document.getElementById("contact_introduction").value

    if(name === "") {
      seterror_name("Name is a required filed.")
      setcheckclicksubmit(false)
    }
    else{
      seterror_name("")
    }

    if(email === "") {
      seterror_email("Email is a required filed.")
      setcheckclicksubmit(false)
    }
    else if(!/\S+@\S+\.\S+/.test(email)) {
      seterror_email("Please provide a valid Email address.")
      setcheckclicksubmit(false)
    }
    else{
      seterror_email("")
    }
    
    if(subject === ""){
      subject = null
    }
    if(introduction === ""){
      introduction = null
    }

    if(capchavalue === ""){
      seterror_recapcha('Please check the box above to proceed.')
      setcheckclicksubmit(false)
    }
    else{
      seterror_recapcha("")
    }


    if(name !== "" && email !== "" && isValidEmail(email) === true && capchavalue !== ""){
      seterror_name("")
      seterror_email("")
      axios.get(
          `https://tccmii.drugmartcanada.ca/formsubmit?name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&subject=${encodeURIComponent(subject)}&introduction=${encodeURIComponent(introduction)}`
        )
        .then((response) => {
          const res = response.data
          if(res === "success"){
            document.getElementById("checkout_loading").style.display= "none";
            setmessage("Your information has been submitted successfully.")
            axios.get(
              `https://tccmii.drugmartcanada.ca/sendnotification?name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&subject=${encodeURIComponent(subject)}&introduction=${encodeURIComponent(introduction)}`
              )
              .then((response) => {
              })
          }
          else{
            setcheckclicksubmit(false)
          }
        })
    }
  } 
  return (
    <div>
      <Helmet>
        <title>TTC Marketing International Inc</title>
        <meta name="description" content="POWERED FOR SMALL BUSINESS GAINS" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <meta property='og:description' content='POWERED FOR SMALL BUSINESS GAINS'/>
        <meta property='og:image' content={`${Header_Logo}`}/>
        <meta property="og:image:type" content="image/png"/>
        <meta property='og:image:width' content='512' />
        <meta property='og:image:height' content='220' />
      </Helmet>
        <div className="whole_content_page">
        <div className="header_content">
          <div id="first_header_content" className="">
            <div className="col-4" id="first_header_content_left">
              <img src={Header_Image} width={700} height={1281} alt="img on header with working team" className="first_header_content_left_ttc_workspace"></img>
              <img src={Header_text_left} width={180} height={130} alt="TTC name" className="first_header_content_left_ttc_name"></img>
            </div>

            <div className="col-8" id="first_header_content_right">
              <div id="first_header_content_right_content">
                <div id="first_header_content_right_content_navbar">
                    {/* <div className="navbar">
                        <a href="/#">ABOUT US</a>
                        <a href="/#">SERVICES</a>
                        <a href="/#">CONTACT US</a>
                    </div> */}
                    <div className="logo_header">
                        <img src={Header_Logo} width={80} height={80} alt="TTC logo"></img>
                    </div>
                </div>
                <div className="first_header_content_right_content_intro">
                <h1>POWERED FOR <br></br>SMALL BUSINESS <br></br>
                <p className="cover_outline_span">
                <span className="outline_span">GAINS<span>.</span></span>

                <span className="outline_span_span">GAINS<span>.</span></span>
                </p>
                </h1>
                <p>We combine innovative technology with our highly engaged representatives to maximize your customer service and conversions. We are growing rapidly as we provide advance multichannel and multilingual solutions to meet your customer needs.</p>
                </div>
               
              </div>            
            </div>
            
          </div>  
        </div>

        
        <div className="customer_service">
            <div  className="first_customer_service"> 
              <div className="row" id="first_customer_service_row">
                <div className="col">
                <div className="customer_service_left">
              <p> Let us meet <br></br>your small <br></br>business <span>customer <br></br>service needs<span>.</span></span></p>
                </div>
                </div>
                <div className="col">
                <div className="customer_service_right">
                  <p> Our experienced staff have been bringing
                      customers closer to businesses since 2018.
                      We have managed to bring in support
                      experts from all over the world including
                      Philippines, El Salvador, Mexico, Canada,
                      and Indonesia. We cater to many different
                      needs based on your goals as a business.
                      Let us help you engage.</p>
                </div>
                </div>
              </div>
              
              
            </div>

            <div style={{"position":"relative"}}> 
                
                <img className="customer_service_laptop" src={graphic2} width={1000} height={631} alt="Two men pointing to laptop"></img>
          
                <img className="customer_service_line" src={Customer_service_line} width={2000} height={120} alt="line botton on customer service"></img>
            </div>
        </div>

        <div className="current_service">
          <div className="current_service_content">
            <p className="service_c_letter"></p>
            <h1>current<span className="service_text"> services</span>.</h1>
            <div  className="services_list">
              <div className="first_service">
                <div className="first_service_top">
                  <div className="service_circle"></div>
                  <div className="service_number">01.</div>
                  <img src={service1} width={450} height={315} alt="call center"></img>
                </div>
                <div>
                  
                    <h5>Call Centre</h5>
                    <p>Our team is here to design the right solutions for
                      you and your business. We view our customers
                      as partners and will work with you to obtain your
                      goals. From surveys to real time customer
                      engagement and problem solving, we can help.
                    
                  </p>
                </div>
              </div>
              <div className="second_service">
                <div className="first_service_top">
                  <div className="service_circle"></div>
                  <div className="service_number">02.</div>
                  <img src={service2} width={450} height={315} alt="call center"></img>
                </div>
                <div>
                  
                    <h5>BPO</h5>
                    <p>Business process outsourcing helps your
                        company focus on growth and opportunities
                        while we take care of not only your calls but
                        other aspects of your business such as
                        marketing programs, operational streamlining,
                        and bookkeeping.
                    </p>
                  
                </div>
              </div>
            </div>

            <div  className="services_list">
              <div className="first_service">
                <div className="first_service_top">
                  <div className="service_circle"></div>
                  <div className="service_number">03.</div>
                  <img src={service3} width={450} height={315} alt="call center"></img>
                </div>
                <div>
                  
                    <h5>OUR GOAL</h5>
                    <p>Our goal is to partner with you and provide
                      innovative solutions to help you grow and
                      expand your business.
                  </p>
                </div>
              </div>
              <div className="second_service">
                <div className="first_service_top">
                  <div className="service_circle"></div>
                  <div className="service_number">04.</div>
                  <img src={service4} width={450} height={315} alt="call center"></img>
                </div>
                <div>
                  
                    <h5>HEALTHCARE</h5>
                    <p>We started in the healthcare business and have
                      grown to support our partner companies in this
                      same field. Online healthcare businesses are
                      one of our specialties. We will work with your
                      current customers and potential customers to
                      find the solutions they need while engaging
                      them with your brand.
                    </p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="come_work_with_us">
          <div className="come_work_with_us_whole_content">
            <div className="come_work_content">
              <p className="come_work__c_letter"></p>
              <h1>COME WORK<span className="come_work__text"> WITH US</span>!</h1>
              <div id="come_work_bottom" className="row">
                  <div  className="col">
                      <div id="come_work_bottom_text">
                        <p>We cater to individual and business
                        advancement by investing in our
                        core resources:
                        </p>
                        <h5>Our Team</h5>
                        <p>We provide promotion and training
                        from within the company. If you’re
                        motivated to learn and improve your
                        knowledge base while focusing on
                        our core values, we will have a place
                        for you.
                        </p>
                      </div>

                  </div>
                  <div id="come_work_bottom_form" className="col">
                  <form>
                      <div className="form-group">
                        <label htmlFor="contact_name">Your Name (required)</label>
                        <input type="text" className="form-control" id="contact_name"/>
                        <p style={{"color":"red"}}>{error_name}</p>

                      </div>
                      <div className="form-group">
                        <label htmlFor="contact_email">Your Email (required)</label>
                        <input type="email" className="form-control" id="contact_email"/>
                        <p style={{"color":"red"}}>{error_email}</p>

                      </div>

                      <div className="form-group">
                        <label htmlFor="contact_subject">Subject</label>
                        <input type="text" className="form-control" id="contact_subject"/>
                      </div>
                      <div className="form-group">
                        <label htmlFor="contact_introduction">Your Introduction</label>
                        <textarea className="form-control" id="contact_introduction" rows={6}></textarea>
                      </div>
                      <div style={{"marginTop":"20px"}}>
                                <ReCAPTCHA
                                sitekey="6Ld5UVwlAAAAAMc4KgDuQ6O2AmNFio_tvg_pnPPk"
                                onChange={onChange}
                                onExpired={onExpired}
                                onErrored={onExpired}
                                />
                                <p style={{"color":"red","marginTop":"20px"}}>{error_recapcha}</p>

                        </div>
                      {checkclicksubmit === false ? 
                      <button id ="button_submit" onClick={handleSubmitForm}>Send <BsArrowRight size={25}></BsArrowRight></button>
                      :
                      <button style={{"backgroundColor":"#1b4484"}}
                        className="btn btn-block in-app-btn btn-override bg-dark border-0 mt-4"
                        id="checkout_loading"
                        disabled
                      >
                        <span 
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                      }
                      <p style={{"color":"red","marginTop":"20px"}}>{message}</p>
                      
                    </form>
                    
                  </div>
              </div>

              <img  className="image_bottom" src={graphic1} width={1500} height={970} alt="success partners"></img>

            </div>
          </div>

        </div>

       </div>
      
      
      <div className="footer">
        <div className="row" id="footer_content">

            <div className="row" id="footer_content_top">
              <div className="col">
                <h6>Contact Us</h6>
                <p><b>TTC Marketing International Inc.</b><br></br>
                 W 811 8th Floor, <br></br>
                 PSEC Exchange Road,<br></br>
                 San Antonio, City of Pasig Metro Manila,<br></br>
                 Second District, Philippines.<br></br>
                 1600
                 </p> 
              </div>
            </div>
          
          <hr></hr>
          <div className="trademark">
            <p><span>© {currentYear} | tccmii.ph</span> All rights reserved.</p>
          </div>
        </div>   
      </div>
 
    </div>
  );
}

export default App;
